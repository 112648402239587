<template>
  <accounts-data-table
    :default-filter="defaultFilter"
    use-user-tags
  />
</template>

<script>
import AccountsDataTable from '@/components/page/accounts/AccountsDataTable.vue'

export default {
  components: {
    AccountsDataTable,
  },
  data() {
    return {
      defaultFilter: {
        account_type: [],
        state: [],
        search: '',
        tag: [],
        userTags: [],
        country: null,
      },
    }
  },
}
</script>
